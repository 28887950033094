import { render, staticRenderFns } from "./warningMessage.vue?vue&type=template&id=755b0a36&scoped=true&"
import script from "./warningMessage.vue?vue&type=script&lang=js&"
export * from "./warningMessage.vue?vue&type=script&lang=js&"
import style0 from "./warningMessage.vue?vue&type=style&index=0&id=755b0a36&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755b0a36",
  null
  
)

export default component.exports