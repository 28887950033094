<template>
  <div style="height: 276px">
    <el-col class="warning-message">
      <el-col class="warning-message-title"><img src="../../assets/warning.png" alt=""><span style="margin-left: 10px">系统消息</span>
      </el-col>
      <el-col class="warning-message-row" v-for="(itm,index) in dataList" :key="index"  @click.native="clickNav(itm.id,itm.gId,itm.pageType)">
        <el-col :span="12" class="warning-message-row-left">
          <el-col style="margin-top: 20px">
            <div :class="[itm.status == 0 ? 'message-row-leftRed' : 'message-row-left']"></div>
            {{ itm.remark }}
          </el-col>
          <el-col class="message-row-time">{{ itm.createTime }}</el-col>
        </el-col>
        <el-col :span="11" style="text-align: right;line-height: 90px"><i class="el-icon-right"></i></el-col>
      </el-col>
      <el-col>
        <el-pagination
            style="margin: 20px 0"
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </el-col>
    </el-col>

  </div>
</template>

<script>
export default {
  name: "warningMessage",
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataList: [],
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    clickNav(res,gid,pageType){
      let params = {
        id: res,
      }
      this.$api.post('error/getById', params, res => {
        console.log(res,res,gid,pageType)
        // 1大棚异常信息 2采购审批通过 3采购完成 4农资入库列表 5农资出库列表
        if(pageType === 1){
          localStorage.setItem('changeNavHead','thingsInterner')
          // localStorage.setItem('url_wuliawnag','/CanopyManagement')
          this.$router.push({
            name: "CanopyManagement",
            query: {
              id: gid
            }
          })
        }else if(pageType === 2){
          localStorage.setItem('changeNavHead','erp')
          localStorage.setItem('selectIndex','2')
          // localStorage.setItem('url_wuliawnag','/erp/agricultural')
          this.$router.push({
            name: "agricultural",
            query: {
              id: gid
            }
          })
        }else if(pageType === 3){
          localStorage.setItem('changeNavHead','erp')
          localStorage.setItem('selectIndex','3')
          // localStorage.setItem('url_wuliawnag','/CanopyManagement')
          this.$router.push({
            name: "agricultural",
            query: {
              id: gid
            }
          })
        }else if(pageType === 4){
          localStorage.setItem('changeNavHead','erp')
          localStorage.setItem('selectIndex','4')
          // localStorage.setItem('url_wuliawnag','/CanopyManagement')
          this.$router.push({
            name: "agricultural",
            query: {
              id: gid
            }
          })
        }else if(pageType === 5){
          localStorage.setItem('changeNavHead','erp')
          localStorage.setItem('selectIndex','5')
          // localStorage.setItem('url_wuliawnag','/CanopyManagement')
          this.$router.push({
            name: "agricultural",
            query: {
              id: gid
            }
          })
        }else if(pageType === 6){
          localStorage.setItem('changeNavHead','erp')
          localStorage.setItem('selectIndex','2')
          // localStorage.setItem('url_wuliawnag','/CanopyManagement')
          this.$router.push({
            name: "farming",
            query: {
              id: gid
            }
          })
        }else if(pageType === 7){
          localStorage.setItem('changeNavHead','erp')
          localStorage.setItem('selectIndex','1')
          // localStorage.setItem('url_wuliawnag','/CanopyManagement')
          this.$router.push({
            name: "warehouse",
            query: {
              id: gid
            }
          })
        }else if(pageType === 8){
          localStorage.setItem('changeNavHead','erp')
          localStorage.setItem('selectIndex','2')
          // localStorage.setItem('url_wuliawnag','/CanopyManagement')
          this.$router.push({
            name: "warehouse",
            query: {
              id: gid
            }
          })
        }

      })
    },
    getDataList() {
      let params = {
        page: this.pageIndex,
        size: this.pageSize,
      }
      this.$api.post('error/getListByBid', params, res => {
        console.log(res)
        this.dataList = res.data.records
        this.totalPage = res.data.total
      })
    }
  }
}
</script>

<style scoped>
.warning-message {
  background: #FFFFFF;
  min-height: 276px;
  margin-top: 20px;
  padding: 0 20px;
}

.warning-message-title {
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;

}

.warning-message-row {
  height: 97px;
  line-height: 30px;
  border-bottom: 1px solid #CCCCCC;
  cursor:pointer;

}

.message-row-left {
  width: 16px;
  height: 16px;
  background: #28C228;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
}
.message-row-leftRed {
  width: 16px;
  height: 16px;
  background: #EA3F04;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
}

.warning-message-row-left {
  margin: 0 10px 0 20px;

}

.message-row-time {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  padding-left: 30px;

}
</style>
